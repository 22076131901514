import { BrokerRole } from "@/types/User";

export enum Permission {
  PROMOTE_MEMBERS = "PROMOTE_MEMBERS",
  ADD_REMOVE_MEMBERS = "ADD_REMOVE_MEMBERS",
  CREATE_A_NEW_APPLICATION = "CREATE_A_NEW_APPLICATION",
  CREATE_A_SALES_ILLUSTRATION = "CREATE_A_SALES_ILLUSTRATION",
  ADD_GROUP_REQUEST = "ADD_GROUP_REQUEST",
  VIEW_GROUP_REQUESTS = "VIEW_GROUP_REQUESTS",
  ADD_A_PROSPECT = "ADD_A_PROSPECT",
  VIEW_INFORCE_POLICY_HOLDERS = "VIEW_INFORCE_POLICY_HOLDERS",
  VIEW_PROSPECTS = "VIEW_PROSPECTS",
  VIEW_PENDING_CASES = "VIEW_PENDING_CASES",
  SEND_REMINDERS = "SEND_REMINDERS",
  VIEW_ALL_ORGANIZATION_PROSPECTS = "VIEW_ALL_ORGANIZATION_PROSPECTS",
  VIEW_ALL_ORGANIZATION_PENDING_CASES = "VIEW_ALL_ORGANIZATION_PENDING_CASES",
  VIEW_ALL_ORGANIZATION_INFORCE_POLICY_HOLDERS = "VIEW_ALL_ORGANIZATION_INFORCE_POLICY_HOLDERS",
  CREATE_LINKS = "CREATE_LINKS",
  VIEW_DOCUMENTS = "VIEW_DOCUMENTS",
  VIEW_AGENT_DOCUMENTS = "VIEW_AGENT_DOCUMENTS",
  VIEWING_AS = "VIEWING_AS",
  CREATE_GROUP_QUOTE = "CREATE_GROUP_QUOTE",
  VIEW_GROUPS = "VIEW_GROUPS",
  CREATE_GROUP_RATE_SHEET = "CREATE_GROUP_RATE_SHEET",
  CREATE_INDIVIDUAL_RATE_SHEET = "CREATE_INDIVIDUAL_RATE_SHEET",
  INDIVIDUAL_RATE_CALCULATOR = "INDIVIDUAL_RATE_CALCULATOR",
}

type PermissionMap = { [Role in BrokerRole]: Permission[] };

export const PermissionsMap: PermissionMap = {
  [BrokerRole.OWNER]: [
    Permission.PROMOTE_MEMBERS,
    Permission.ADD_REMOVE_MEMBERS,
    Permission.CREATE_A_NEW_APPLICATION,
    Permission.CREATE_A_SALES_ILLUSTRATION,
    Permission.VIEW_GROUP_REQUESTS,
    Permission.CREATE_INDIVIDUAL_RATE_SHEET,
    Permission.INDIVIDUAL_RATE_CALCULATOR,
    Permission.CREATE_GROUP_RATE_SHEET,
    Permission.ADD_GROUP_REQUEST,
    Permission.ADD_A_PROSPECT,
    Permission.VIEW_INFORCE_POLICY_HOLDERS,
    Permission.VIEW_PROSPECTS,
    Permission.VIEW_PENDING_CASES,
    Permission.SEND_REMINDERS,
    Permission.VIEW_ALL_ORGANIZATION_PROSPECTS,
    Permission.VIEW_ALL_ORGANIZATION_PENDING_CASES,
    Permission.VIEW_ALL_ORGANIZATION_INFORCE_POLICY_HOLDERS,
    Permission.CREATE_LINKS,
    Permission.VIEW_DOCUMENTS,
    Permission.VIEW_AGENT_DOCUMENTS,
    Permission.VIEWING_AS,
    Permission.CREATE_GROUP_QUOTE,
    Permission.VIEW_GROUPS,
  ],
  [BrokerRole.MANAGER]: [
    Permission.ADD_REMOVE_MEMBERS,
    Permission.CREATE_A_NEW_APPLICATION,
    Permission.CREATE_A_SALES_ILLUSTRATION,
    Permission.VIEW_GROUP_REQUESTS,
    Permission.CREATE_INDIVIDUAL_RATE_SHEET,
    Permission.INDIVIDUAL_RATE_CALCULATOR,
    Permission.CREATE_GROUP_RATE_SHEET,
    Permission.ADD_GROUP_REQUEST,
    Permission.ADD_A_PROSPECT,
    Permission.VIEW_INFORCE_POLICY_HOLDERS,
    Permission.VIEW_PROSPECTS,
    Permission.VIEW_PENDING_CASES,
    Permission.SEND_REMINDERS,
    Permission.CREATE_LINKS,
    Permission.VIEW_AGENT_DOCUMENTS,
    Permission.VIEW_DOCUMENTS,
    Permission.CREATE_GROUP_QUOTE,
    Permission.VIEW_GROUPS,
  ],
  [BrokerRole.MEMBER]: [
    Permission.CREATE_A_NEW_APPLICATION,
    Permission.CREATE_A_SALES_ILLUSTRATION,
    Permission.VIEW_GROUP_REQUESTS,
    Permission.CREATE_INDIVIDUAL_RATE_SHEET,
    Permission.INDIVIDUAL_RATE_CALCULATOR,
    Permission.CREATE_GROUP_RATE_SHEET,
    Permission.ADD_GROUP_REQUEST,
    Permission.ADD_A_PROSPECT,
    Permission.VIEW_INFORCE_POLICY_HOLDERS,
    Permission.VIEW_PROSPECTS,
    Permission.VIEW_PENDING_CASES,
    Permission.SEND_REMINDERS,
    Permission.CREATE_LINKS,
    Permission.VIEW_AGENT_DOCUMENTS,
    Permission.VIEW_DOCUMENTS,
    Permission.CREATE_GROUP_QUOTE,
    Permission.VIEW_GROUPS,
  ],
  [BrokerRole.BACK_OFFICE_STAFF]: [
    Permission.CREATE_A_SALES_ILLUSTRATION,
    Permission.VIEW_GROUP_REQUESTS,
    Permission.CREATE_INDIVIDUAL_RATE_SHEET,
    Permission.INDIVIDUAL_RATE_CALCULATOR,
    Permission.CREATE_GROUP_RATE_SHEET,
    Permission.ADD_GROUP_REQUEST,
    Permission.VIEW_INFORCE_POLICY_HOLDERS,
    Permission.VIEW_PROSPECTS,
    Permission.VIEW_PENDING_CASES,
    Permission.SEND_REMINDERS,
    Permission.VIEW_ALL_ORGANIZATION_PROSPECTS,
    Permission.VIEW_ALL_ORGANIZATION_PENDING_CASES,
    Permission.VIEW_ALL_ORGANIZATION_INFORCE_POLICY_HOLDERS,
    Permission.CREATE_LINKS,
    Permission.VIEW_DOCUMENTS,
    Permission.VIEW_AGENT_DOCUMENTS,
    Permission.VIEWING_AS,
    Permission.CREATE_GROUP_QUOTE,
    Permission.VIEW_GROUPS,
  ],
};
