import React, { ReactNode, useEffect, useRef, useState } from "react";
import { getUserId, getUserRole } from "@/services/auth";
import {
  getStoredBrokerDetails,
  setStoredBrokerDetails,
} from "@/services/user";
import { IOption } from "@/types/Option";
import { IBrokerDetails } from "@/types/User";
import { useContext } from "react";
import { usePermissionContext } from "./PermissionContext";
import { RoleGroup } from "@/types/RoleGroup";

interface IThemeContext {
  user: IBrokerDetails | undefined;
  setUser: React.Dispatch<React.SetStateAction<IBrokerDetails | undefined>>;
  brokerIdToImpersonate: IOption | undefined;
  setBrokerIdToImpersonate: React.Dispatch<
    React.SetStateAction<IOption | undefined>
  >;
  organizationToImpersonate: IOption | undefined;
  setOrganizationToImpersonate: React.Dispatch<
    React.SetStateAction<IOption | undefined>
  >;
}

export const UserContext = React.createContext<IThemeContext>({
  user: undefined,
  setUser: () => {},
  brokerIdToImpersonate: undefined,
  setBrokerIdToImpersonate: () => {},
  organizationToImpersonate: undefined,
  setOrganizationToImpersonate: () => {},
});

const UserContextProvider = ({ children }: { children: ReactNode }) => {
  const firstRender = useRef(true);

  const { updatePermissions } = usePermissionContext();

  const [user, setUser] = useState<IBrokerDetails | undefined>(
    getStoredBrokerDetails()
  );
  const [organizationToImpersonate, setOrganizationToImpersonate] = useState<
    IOption | undefined
  >();

  const [brokerIdToImpersonate, setBrokerIdToImpersonate] = useState<
    IOption | undefined
  >();

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    user && setStoredBrokerDetails(user);
  }, [user]);

  useEffect(() => {
    const brokerRole = user?.role;
    if (brokerRole) updatePermissions(brokerRole);
  }, [JSON.stringify(brokerIdToImpersonate)]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        brokerIdToImpersonate,
        setBrokerIdToImpersonate,
        organizationToImpersonate,
        setOrganizationToImpersonate,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);

export default UserContextProvider;
