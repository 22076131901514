import React, { ReactNode, useContext, useEffect, useState } from "react";
import { Permission } from "@/constants/permission";
import { BrokerRole } from "@/types/User";
import NavbarLayout from "@/layouts/NavBarLayout";
import { Loader } from "@reframe-financial/chaplin";
import { getBrokerRole, getUserRole, isLoggedIn } from "@/services/auth";
import { RoleGroup } from "@/types/RoleGroup";
import { ClipLoader } from "react-spinners";
import { getBrokerPermissions } from "@/helpers/permission";

type PermissionTarget =
  | Permission
  | ((permissionList: Permission[], role: BrokerRole) => boolean);

interface IPermissionContext {
  permissions: Permission[];
  updatePermissions: (brokerRole: BrokerRole) => Promise<void>;
  hasPermission: (target: PermissionTarget) => boolean;
  registerAdminLogin: () => void;
}

export const PermissionContext = React.createContext<IPermissionContext>({
  hasPermission: (target: PermissionTarget) => false,
  updatePermissions: async (brokerRole) => {},
  registerAdminLogin: () => {},
  permissions: [],
});

export const PermissionContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [permissions, setPermissions] = useState<Permission[] | undefined>([]);
  const [loading, setLoading] = useState(true);
  const [brokerRole, setBrokerRole] = useState<BrokerRole | undefined>();
  const [cognitoGroup, setCognitoGroup] = useState<string>();

  const hasPermission = (target: PermissionTarget): boolean => {
    if (cognitoGroup === "admin-group") return true;

    if (typeof target === "function") {
      return target(permissions || [], brokerRole!);
    }

    return permissions?.includes(target as Permission) || false;
  };

  const updatePermissions = async (brokerRole: BrokerRole) => {
    const permissions = getBrokerPermissions(brokerRole);
    setPermissions(permissions);
  };

  const registerAdminLogin = () => {
    // if admin logged, getUserRole will return 'admin-group' we should just get it to the local state
    setCognitoGroup(getUserRole());
  };

  useEffect(() => {
    async function getUserPermissions() {
      try {
        if (!isLoggedIn()) return;

        if (getUserRole() === RoleGroup.Admin) {
          return setCognitoGroup(RoleGroup.Admin);
        }

        const role = getBrokerRole() as BrokerRole;
        setBrokerRole(role);

        await updatePermissions(role);
      } finally {
        setLoading(false);
      }
    }

    getUserPermissions();
  }, []);

  const providerValue: IPermissionContext = {
    hasPermission,
    updatePermissions,
    registerAdminLogin,
    permissions: permissions || [],
  };
  return (
    <PermissionContext.Provider value={providerValue}>
      {loading ? (
        <NavbarLayout logoDark={false} childrenContainerStyles="max-w-7xl">
          <div className="h-screen flex items-center justify-center">
            <Loader icon={<ClipLoader />} />
          </div>
        </NavbarLayout>
      ) : (
        children
      )}
    </PermissionContext.Provider>
  );
};

export const usePermissionContext = () => useContext(PermissionContext);
